import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider, Fade, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';

import { useAuth0 } from '@auth0/auth0-react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { PAGE_ROUTES } from '../Configs/Routes';
import { color } from '../Styles/Color';

export const toPascalCase = (str, id) => {

  if (str === "/") {
    return []
  }

  return str
    .split('/')
    .map((word) =>
      word !== id ?
        word.charAt(0).toUpperCase() + word.slice(1)
        : word
    )
    .join(' / ')
    .split(' ');
};

export const Header = () => {

  const location = useLocation()
  const nav = useNavigate()

  const { logout } = useAuth0();

  const name = useSelector(state => state.userState.user.nickname) || "User"

  const { id } = useParams()
  const path = toPascalCase(location.pathname, id).filter(item => item.length > 0)

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      height="8vh"
      borderBottom="1px solid #E4E4E4"
      justifyContent="space-between"
      alignItems="center"
      direction="row"
      px={3}
    >
      <Stack direction="row" gap={1}>
        <Typography
          variant='h2'
          color={path.length > 0 && color.secondaryText}
          sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }}
          onClick={() => nav(PAGE_ROUTES.dashboard)}
        >
          Home
        </Typography>
        {
          path
            .map(
              (item, idx) =>
                <Typography
                  key={idx}
                  variant='h2'
                  color={idx !== (path.length - 1) && color.secondaryText}
                  sx={{
                    "&:hover": {
                      cursor: "pointer"
                    }
                  }}
                  onClick={() =>
                    idx !== (path.length - 1) && nav(path.slice(0, idx + 1).join("").toLowerCase())
                  }
                >
                  {item}
                </Typography>
            )
        }
      </Stack>
      <Stack direction="row" width="50%" justifyContent="end">
        <Stack
          direction="row"
          gap={1} px={2}
          color={color.primary}
          alignItems="center"
          sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }}
          onClick={() => window.open("https://docs.scalegen.ai/category/tutorials", "_blank")}
        >
          <HelpIcon
            fontSize='small'
          />
          <Typography
            variant='body1'
          >
            Tutorials
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack
          direction="row"
          gap={1} px={2}
          color={color.primary}
          alignItems="center"
          sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }}
          onClick={() => window.open("https://docs.scalegen.ai", "_blank")}
        >
          <MenuBookIcon
            fontSize='small'
          />
          <Typography
            variant='body1'
          >
            Docs
          </Typography>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack
          direction="row"
          gap={1}
          mx={2} p={0.8}
          pr={1}
          alignItems="center"
          borderRadius="8px"
          bgcolor={open && "#f4f4f4"}
          sx={{
            "&:hover": {
              bgcolor: "#f4f4f4",
              cursor: "pointer"
            }
          }}
          onClick={handleClick}
        >
          <Avatar
            variant="rounded"
            sx={{
              width: 24,
              height: 24,
              bgcolor: `${color.primary}`,
              fontSize: "16px",
              borderRadius: "6px"
            }}
          >
            {name ? `${name[0]?.toUpperCase()}` : <PersonIcon fontSize='small' />}
          </Avatar>
          <Typography
            variant='body1'
          >
            {name || ""}
          </Typography>
          <Stack color={color.primary} pl={1}>
            <ExpandMoreIcon fontSize='small' />
          </Stack>
        </Stack>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={
          {
            vertical: 'bottom',
            horizontal: 'center',
          }
        }
      >
        <MenuList dense>
          <MenuItem
            onClick={() => {
              nav(PAGE_ROUTES.setting)
              handleClose()
            }}
          >
            <ListItemIcon><ManageAccountsIcon /></ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              logout({
                logoutParams: {
                  returnTo: window.location.origin
                }
              })
            }}
          >
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText>Logout </ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Stack>
  )
}
