import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import CheckIcon from '@mui/icons-material/Check'
import CloudIcon from '@mui/icons-material/Cloud'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DnsIcon from '@mui/icons-material/Dns'
import DynamicFormIcon from '@mui/icons-material/DynamicForm'
import LanguageIcon from '@mui/icons-material/Language'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import ScheduleIcon from '@mui/icons-material/Schedule'
import ViewInArIcon from '@mui/icons-material/ViewInAr'
import { Divider, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { getStatus } from '../../Components/TrainingViewComponents/JobsList'
import CustomButton from '../../Components/UiComponents/CustomButton'
import Loader from '../../Components/UiComponents/Loader'
import { PAGE_ROUTES } from '../../Configs/Routes'
import { useDeleteJobMutation, useGetJobGPUNodesQuery } from '../../Services/jobsApi'
import { useGetEventLogsQuery } from '../../Services/logsApi'
import { color } from '../../Styles/Color'

export const FinetuneJobStatus = ({ job, config }) => {

  const nav = useNavigate()

  const { data } = useGetEventLogsQuery(job?.id)
  const [deleteJob, { isLoading: isDeleting }] = useDeleteJobMutation()
  const { data: gpuNodes, isLoading: isLoadingGPUNodes } = useGetJobGPUNodesQuery(
    job?.id,
    {
      skip: (job.status === "DELETED" || job.status === "FAILED")
    }
  )

  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (text) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
  }


  if (isDeleting || isLoadingGPUNodes) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack direction="row" gap={2} py={2}>
      <Stack width="65%" spacing={5}>
        <Stack direction="row" justifyContent="space-between" alignItems="end">
          <Stack direction="row" gap={1} alignItems="self-end">
            <Typography variant="h1">{job.name}</Typography>
            <Typography variant="body1" color={color.primary}>{job.id}</Typography>
          </Stack>
          {
            config &&
            <CustomButton
              onClick={() =>
                nav(
                  PAGE_ROUTES.relaunchFinetune,
                  {
                    state: {
                      config: config
                    }
                  }
                )
              }
            >
              Relaunch Job
            </CustomButton>
          }
        </Stack >
        <Stack
          p={3}
          border={`1px solid ${color.borders}`}
          spacing={4}
          boxShadow="0px 1px 4px 0px #0000000A"
          borderRadius="6px"
        >
          <Stack direction="row" gap={2} alignItems="center">
            {
              config?.model && <>
                <ViewInArIcon sx={{ color: color.primary, fontSize: "16px" }} />
                <Typography variant='h3'>{config.model}</Typography>
                <Tooltip title={isCopied ? "Copied" : "Copy"}>
                  <ContentCopyIcon
                    sx={{
                      fontSize: "16px",
                      color: color.primary,
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }}
                    onClick={() =>
                      handleCopyClick(config.model)
                    }
                  />
                </Tooltip>
              </>
            }
            {getStatus(job.status.toLowerCase())}
          </Stack>
          <Stack
            spacing={2}
            maxHeight="35vh"
            overflow="auto"
            sx={{
              '&::webkit-scrollbar': {
                display: 'none'
              }
            }}
            pr={2}
          >
            {
              data?.map((status, idx) => (
                <>
                  <Stack direction="row" gap={1} justifyContent="space-between" >
                    <Stack direction="row" gap={2} width="80%">
                      {
                        (status.metadata === null ||
                          (status.metadata['error'] === undefined ||
                            !status.metadata['error'])
                        )
                          ?
                          <CheckIcon sx={{ fontSize: "20px", color: color.successText }} />
                          :
                          <PriorityHighIcon sx={{ fontSize: "20px", color: color.deleteText }} />
                      }
                      <Typography fontSize="13px">{status.message}</Typography>
                    </Stack>
                    <Typography fontSize="13px" color="#ABABAB">{status.timestamp.split(".")[0]}</Typography>
                  </Stack>
                </>
              )).reverse()
            }
          </Stack>
          {
            job.status !== "FAILED" &&
            <>
              <Divider />
              <Stack py={1} direction='row' justifyContent="space-between" alignItems="center">
                <Stack direction="row" gap={1} alignItems="center">
                  {
                    (job.status === "STOPPING" || job.status === "STOPPED") &&
                    <>
                      <ArrowCircleDownIcon fontSize='small' sx={{ color: color.deleteText }} />
                      <Stack direction="row" gap={1}>
                        <Typography variant='body2'>
                          Job {job.status === "STOPPING" ? "is being" : "has been"}  stopped. {job.status === "STOPPED" && "Click on relaunch job to launch again."}
                        </Typography>
                      </Stack>
                    </>
                  }
                  {
                    job.status === "RUNNING" &&
                    <>
                      <ArrowCircleUpIcon fontSize='small' sx={{ color: color.successText }} />
                      <Typography variant='body2'>Job is running and utilizing resources.</Typography>
                    </>
                  }
                  {
                    job.status === "QUEUED" &&
                    <Typography variant='body2'>
                      Job is currently queued. This may take a few moments.
                    </Typography>
                  }
                </Stack>
                {
                  !(job.status === "STOPPING" || job.status === "STOPPED") &&
                  <Typography
                    variant='body2'
                    color={color.deleteText}
                    sx={{
                      "&:hover": {
                        cursor: "pointer"
                      }
                    }}
                    onClick={() => deleteJob(job.id)}
                  >
                    Stop Job
                  </Typography>
                }
              </Stack>
            </>
          }
        </Stack>
      </Stack>
      <Stack width="35%" spacing={2} mt={-2}>
        <Stack
          p={3}
          border={`1px solid ${color.borders}`}
          spacing={3}
          boxShadow="0px 1px 4px 0px #0000000A"
          borderRadius="6px"
        >
          <Typography variant='h2'>Usage</Typography>
          <Stack direction="row" gap={1} alignItems="end">
            <Typography variant='h1'>$ {Math.round(job.cost * 100) / 100}</Typography>
            <Typography variant='subtitle1' color={color.secondaryText}>cost for this job</Typography>
          </Stack>
        </Stack>
        {gpuNodes?.length > 0 &&
          <Stack
            p={3}
            border={`1px solid ${color.borders}`}
            spacing={3}
            boxShadow="0px 1px 4px 0px #0000000A"
            borderRadius="6px"
          >
            <Typography variant='h2'>Active GPU Machines</Typography>
            {
              gpuNodes.map((node, idx) =>
                <Stack
                  key={idx}
                  border={`1px solid ${color.borders}`}
                  p={2}
                  borderRadius="6px"
                  spacing={2}
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <DynamicFormIcon fontSize='small' sx={{ color: color.primary }} />
                    <Stack>
                      <Typography variant='h3'>{node.gpu_count} x {node.gpu_type}</Typography>
                      <Typography variant='subtitle1' color={color.primary}>
                        {
                          node.id.length > 20 ?
                            node.id.slice(0, 20) + "..." : node.id
                        }
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center" flexWrap="wrap">
                    <Stack
                      borderRadius="6px"
                      bgcolor={color.primary}
                      py={1}
                      px={1}
                      direction="row"
                      alignItems="center"
                      gap={0.5}
                      color={color.white}
                    >
                      <CloudIcon sx={{ fontSize: "16px" }} />
                      <Typography variant='subtitle1' >{node.cloud}</Typography>
                    </Stack>
                    <Stack
                      borderRadius="6px"
                      bgcolor={color.primary}
                      py={1}
                      px={1}
                      direction="row"
                      alignItems="center"
                      gap={0.5}
                      color={color.white}
                    >
                      <LanguageIcon sx={{ fontSize: "16px" }} />
                      <Typography variant='subtitle1' >{node.region}</Typography>
                    </Stack>
                    {
                      node.spot ?
                        <Stack
                          borderRadius="6px"
                          bgcolor={color.yellow}
                          py={1}
                          px={1}
                          direction="row"
                          alignItems="center"
                          gap={0.5}
                          color={color.white}
                        >
                          <ScheduleIcon sx={{ fontSize: "16px" }} />
                          <Typography variant='subtitle1' >Spot</Typography>
                        </Stack>
                        :
                        <Stack
                          borderRadius="6px"
                          bgcolor={color.successText}
                          py={1}
                          px={1}
                          direction="row"
                          alignItems="center"
                          gap={0.5}
                          color={color.white}
                        >
                          <DnsIcon sx={{ fontSize: "16px" }} />
                          <Typography variant='subtitle1' >On-Demand</Typography>
                        </Stack>
                    }
                  </Stack>
                </Stack>
              )
            }
          </Stack>
        }
      </Stack>
    </Stack>
  )
}
